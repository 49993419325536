import { BrowserRouter as Router } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import './App.css';
import { AppWithRouterAccess } from './AppWithRouterAccess';
import './i18n';
import { RTL, useRtl } from './RTL';
import { theme } from './theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline, ThemeProvider } from '@mui/material';

function App() {
  const rtl = useRtl();

  useEffectOnce(() => {
    if (rtl) {
      document.documentElement.setAttribute('dir', 'rtl');
    }
  });

  return (
    <RTL>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          autoClose={false}
          rtl={rtl}
          position={rtl ? 'bottom-right' : 'bottom-left'}
          draggable
          pauseOnHover
          hideProgressBar={true}
        />

        <Router>
          <AppWithRouterAccess />
        </Router>
      </ThemeProvider>
    </RTL>
  );
}

export default App;
