import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  titleText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(12),
  },

  container: {
    maxWidth: 352,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(6),
    boxSizing: 'initial',
  },

  footerContainer: {
    '& p': {
      color: theme.extraOptions.newPallette.MediumGrey,
    },
  },

  footerLogo: {
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1),
  },
}));
