import { AuthApi, Configuration, InfoApi } from '@retrain-ai/sign-in-manager-api';
const config = new Configuration({ basePath: window.location.origin });
export const signInInfoApi = new InfoApi(config);
export const authApi = new AuthApi(config);

export const getSignInfo = async () => {
  return window.vm.signInInfo;
};

export const navigateToFallbackUrl = () => {
  window.location.href = 'https://app.retrain.ai';
};
