import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import he from './locales/he.json';
import { SignInCustomizationDto, SignInInfoDto } from '@retrain-ai/sign-in-manager-api';
i18n
  // .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    initImmediate: true,
    debug: false,
    defaultNS: 'default',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        default: en,
      },
      he: {
        default: he,
      },
    },
  });

declare global {
  interface Window {
    vm: {
      language: 'he' | 'en' | '';
      tracking?: {
        debug?: boolean;
        enable?: boolean;
      };
      customizationData?: SignInCustomizationDto;
      signInInfo: SignInInfoDto;
    };
  }
}

i18n.changeLanguage(window.vm.language || 'en');
export default i18n;
