import React, { useEffect, useImperativeHandle, useRef } from 'react';
import OtpInput from 'react18-input-otp';
import { OtpRef, VerificationProps } from '../types';
import { useStyles } from './VerificationScreenStyle';
import { TextVariants, Text, Button } from '@retrain-ai/ui-design';
import { useTranslation } from 'react-i18next';
import { useCommonStyles } from '../Otp.style';
import { useRtl } from '../../../../RTL';
import { Link } from '@mui/material';
import { useCustomization } from '../../../../components/customization/customization';

export const VerificationScreen: React.FC<VerificationProps> = (props) => {
  const { verifyCode, onOtpChange, otpError, otp, isDisabled, otpRef, invalidUserError } = props;
  const { classes: s, cx } = useStyles();
  const { classes: c } = useCommonStyles();
  const { t } = useTranslation();
  const rtl = useRtl();
  const customization = useCustomization();

  const inputRef = useRef<OtpInput>(null);
  const componentContainerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(
    otpRef,
    (): OtpRef => ({
      focus: () => {
        inputRef.current?.focusInput(1);
        const container = componentContainerRef.current?.querySelector('#otp-container');
        if (container) {
          container.classList.add(s.otpContainerActive);
        }
      },
    }),
  );

  const isVerifyButtonDisabled = isDisabled || otp.length !== 6;

  // change style of all OTP cells
  useEffect(() => {
    const handleEvent = (event: MouseEvent) => {
      const container = componentContainerRef.current?.querySelector('#otp-container');
      if (!container) {
        return;
      }

      const target = event.target as HTMLElement;

      target?.nodeName === 'INPUT' && target.id.startsWith('otp-digit') && !isDisabled
        ? container.classList.add(s.otpContainerActive)
        : container.classList.remove(s.otpContainerActive);
    };

    document.body.addEventListener('click', handleEvent);

    return () => {
      document.body.removeEventListener('click', handleEvent);
    };
  }, [isDisabled, s.otpContainerActive]);

  return (
    <div className={s.sofiClientContainer} ref={componentContainerRef}>
      <Text variant={TextVariants.ExtraSmallSubtitle} className={cx(s.otpLabel, s.otpLabelSofiClient)}>
        {t('otp.code')}
      </Text>

      <div id="otp-container" data-testid="otp-container">
        <OtpInput
          id="otp-digit"
          ref={inputRef}
          value={otp}
          onChange={onOtpChange}
          numInputs={6}
          isInputNum={true}
          shouldAutoFocus={false}
          containerStyle={cx(s.otpSofiClientInputContainer, {
            [s.otpContainerError]: !!otpError,
            [s.otpContainerRtl]: !!rtl,
          })}
          inputStyle={cx(s.otpCell, s.otpSofiClientCell)}
          data-testid={'otp'}
          autoComplete="one-time-code"
          isDisabled={isDisabled}
        />
      </div>

      {!!otpError && (
        <Text variant={TextVariants.HintTooltip} className={cx(c.otpError, { [c.visible]: !!otpError })}>
          {otpError}
        </Text>
      )}
      {invalidUserError && (
        <div className={s.credentialsContainer}>
          <Text variant={TextVariants.HintTooltip} className={s.credentialsError}>
            {t('errors.credentials')}
          </Text>
          <Link style={{ textDecoration: 'none', cursor: 'pointer' }} href={customization?.wizardRedirectLink || ''}>
            <Text variant={TextVariants.HintTooltip} className={s.credentialsLink}>
              {t('errors.credentials-link')}
            </Text>
          </Link>
        </div>
      )}

      <Button
        onClick={verifyCode}
        className={c.verifySofiClientOtp}
        disabled={isVerifyButtonDisabled}
        data-testid="verify-code-button"
        size="large"
      >
        <Text variant={TextVariants.SubtitleSemiBold}>{t('otp.verify')}</Text>
      </Button>
    </div>
  );
};
