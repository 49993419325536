import { Children, FC, isValidElement, ReactNode } from 'react';
import { useCustomization } from '../customization/customization';
import { SignInCustomizationDto } from '@retrain-ai/sign-in-manager-api';

type SettingKey = keyof SignInCustomizationDto;

// TODO: Move to ui-design?
const useLayoutKey = <K extends SettingKey>(setting: K): SignInCustomizationDto[K] => {
  const customization = useCustomization();
  const layout = customization?.[setting] ?? 'default';
  return layout;
};

type ILayoutSwitcherProps = { children: ReactNode; setting: SettingKey };
type ILayoutSwitcher = FC<ILayoutSwitcherProps> & { Case: FC<{ type: string; children: ReactNode }> };

// TODO: Move to ui-design?
const LayoutSwitcherComponent: FC<ILayoutSwitcherProps> = ({ children, setting }) => {
  const layout = useLayoutKey(setting);
  const layoutMap: Record<string, ReactNode> =
    Children.toArray(children)
      .map((child) => {
        if (isValidElement(child)) {
          return [child.props.type, child.props.children];
        }
        return null;
      })
      .filter((child) => child !== null)
      .reduce((map, entries) => {
        const [key, value] = entries as [string, ReactNode];
        map[key] = value;
        return map;
      }, {} as Record<string, ReactNode>) ?? {};

  if (!layoutMap['default']) {
    throw new Error('You must provide a default layout');
  }

  return <>{layoutMap[layout]}</>;
};

(LayoutSwitcherComponent as ILayoutSwitcher).Case = ({ children }) => {
  return <>{children}</>;
};

export const LayoutSwitcher = LayoutSwitcherComponent as ILayoutSwitcher;
